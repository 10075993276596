<template>
    <v-container class="query">
        <div class="input-item">
            <span>身份证号</span>
            <input type="text" v-model="idCard">
        </div>
        <div class="save-as-usual">
             <v-checkbox
                class="checkbox"
                v-model="usual"
                label="保存为常用"
            ></v-checkbox>
        </div>
        <v-btn
            class="query-btn"
            depressed
            rounded
            color="primary"
            @click="query"
        >
            查询
        </v-btn>
    </v-container>
</template>

<script>
import {getURLParame} from '@/util/util';
export default {
    name: 'Query',

    data() {
        return {
            usual: false,
            idCard: '',
          organ_id: ''
        }
    },

    beforeMount() {
        console.log('Query beforeMount', window.location.href)
        const idCard = localStorage.getItem('idCard')
        this.idCard = idCard || '';
        this.usual = !!idCard;
        this.organ_id = getURLParame('organ_id');
    },

    methods: {
        query() {
            if (!this.idCard) {
                this.$toast.error('请输入身份证号')
            }
            if (this.usual) {
                localStorage.setItem('idCard', this.idCard);
            }
            if (this.organ_id == undefined || this.organ_id == '' || this.organ_id == null) {
                this.organ_id = localStorage.getItem('organ');
            }
            this.$router.push({
                name: 'bill',
                query: {
                    id: this.idCard,
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.checkbox ::v-deep .v-input__control{
    .v-input--selection-controls__input {
        margin-right: 4px;
        margin-top: -1px;
    }
    .v-messages {
        display: none;
    }
}
.query-btn {
    width: 100%;
    height: 40px !important;
    margin-top: 40px;
}
.query {
    padding-top: 50px;
}
.save-as-usual {
    width: 130px;
}
</style>
